import React, { useEffect } from "react";
import { graphql, navigate } from "gatsby";

import PortfolioGroupTemplate from "../components/v2/templates/portfolioGroupTemplate";
import { RouteLinks } from "../utils/route-links";

const PackageGroupTemplate = (props: any) => {
  if (process.env.GATSBY_VERSION === "v1") {
    useEffect(() => {
      navigate(RouteLinks["404"]);
    }, []);

    return null;
  } else {
    return <PortfolioGroupTemplate {...props} />;
  }
};

export const pageQuery = graphql`
  query($slug: String!) {
    portfolios: allMarkdownRemark(
      filter: { frontmatter: { portfolio_group: { eq: $slug } } }
      sort: { fields: frontmatter___order, order: ASC }
    ) {
      edges {
        node {
          frontmatter {
            title
            slug
          }
        }
      }
    }

    portfolioGroups: allMarkdownRemark(
      filter: { fields: { category: { eq: "portfolioGroups" } } }
      sort: { order: ASC, fields: fileAbsolutePath }
    ) {
      edges {
        node {
          frontmatter {
            title
            slug
          }
        }
      }
    }
  }
`;

export default PackageGroupTemplate;
