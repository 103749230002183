import React from "react";

import LandingComponent from "../../homepage/landing-component/Landing";
import { GqlListResponse } from "../../../../models/gql-list-response.model";
import { Portfolio } from "../../../../models/portfolio.model";
import { PortfolioGroup } from "../../../../models/portfolio-group.model";
import { getPortfolioGroupLink } from "../../../../utils/link.helper";

const PortfolioGroupTemplate = (props: any) => {
  const slug = props.pageContext.slug;
  const cta = props.pageContext.cta;

  const portfoliosRaw: GqlListResponse<Portfolio> = props.data.portfolios;
  const portfolios = portfoliosRaw.edges;

  const portfolioGroupsRaw: GqlListResponse<PortfolioGroup> =
    props.data.portfolioGroups;
  const portfolioGroups = portfolioGroupsRaw.edges;

  const navLinks: { text: string; route: string }[] = [];
  portfolioGroups.map(el => {
    const {
      node: {
        frontmatter: { title, slug },
      },
    } = el;
    navLinks.push({
      text: title,
      route: getPortfolioGroupLink(slug),
    });
  });

  return (
    <LandingComponent
      portfolios={portfolios}
      portfolioMobileImages={props.pageContext.mobile_images}
      portfolioDesktopImages={props.pageContext.images}
      pageUrl={getPortfolioGroupLink(slug)}
      navLinks={navLinks}
      menuSelected={slug}
      slug={getPortfolioGroupLink(slug)}
      cta={cta}
    />
  );
};

export default PortfolioGroupTemplate;
